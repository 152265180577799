import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Rating, List, ListItem, ListItemText } from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const ProfileCard = ({profile}) => {
  const images = [
    'https://images.pexels.com/photos/6319865/pexels-photo-6319865.jpeg',
    'https://images.pexels.com/photos/1234567/pexels-photo-1234567.jpeg',
    'https://images.pexels.com/photos/9876543/pexels-photo-9876543.jpeg',
  ];

  const handleSlideChange = (event) => {
    console.log('Slide changed:', event.item);
  };

  const renderDotsItem = (_, i) => <span key={i} />;
  
  return (
    <Card>
      <AliceCarousel
        mouseTracking
        disableButtonsControls
        items={images.map((image, index) => (
          <img key={index} src={image} alt={`Image ${index}`} style={{ width: '100%', height: 'auto' }} />
        ))}
        onSlideChanged={handleSlideChange}
        // renderDotsItem={renderDotsItem}
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <Link to={`/${profile.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {profile.name}
          </Link>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Address Line 1, Address Line 2, City, State, Zip Code
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Phone Number: 123-456-7890
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: example@example.com
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Website: www.example.com
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Reviews:
          </Typography>
          <Box sx={{ mb: 1 }}>
            <Rating value={4.5} readOnly precision={0.5} />
          </Box>
          <Typography variant="body2">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Services:
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText primary="Service 1" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Service 2" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Service 3" />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
