import React from 'react';
import { Avatar, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Link } from 'react-router-dom';

const CustomLink = React.forwardRef(({ onClick, to, ...rest }, ref) => {
  return (
    <Link to={to} ref={ref} {...rest} style={{ textDecoration: 'none', color: 'inherit' }} onClick={(event) => onClick && onClick(event)}>
      {rest.children}
    </Link>
  );
});

const Bill = ({profile, services}) => {
  const filteredAddress = profile.addresses?.filter(item => item.is_business_address);
  const businessAddress = filteredAddress?.length>0 ? filteredAddress[0].address : "";
  const currency = getSymbolFromCurrency(services?.length>0 ? services[0].price_currency : "");

  const totalCost = services.reduce((sum, service) => sum + service.price, 0);

  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: 400 }}>
      <CustomLink to={`/${profile.slug}`}>
        <Avatar sx={{ width: 100, height: 100, mx: 'auto', my: 2 }} />
      </CustomLink>

      <CustomLink to={`/${profile.slug}`}>
        <Typography variant="h5" align="center" gutterBottom>
          {profile.name}
        </Typography>
      </CustomLink>

      <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
        {businessAddress}
      </Typography>

      <List>
        {services.map((service, index) => (
          <ListItem key={index}>
            <ListItemText primary={service.name} secondary={`${currency} ${service.price}`} />
          </ListItem>
        ))}
      </List>

      <Typography variant="h6" align="right" gutterBottom>
        Total: {currency} {totalCost}
      </Typography>
    </Paper>
  );
};

export default Bill;
