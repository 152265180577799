import { useEffect, useState } from 'react';
import { AppBar, Box, Button, Radio, Divider, RadioGroup, Collapse, Checkbox, Chip, Container, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import {dataProvider} from '../../hooks/dataProvider';
import { useParams } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ServiceSelect = ({checked, setChecked, setNext, setProfileId}) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState('');
  const { slug } = useParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const pid = urlParams.get('pid');
    fetchServices(pid ? {'profile': pid} : {'slug': slug}); // if profile id is provided, then search by pofile id, otherwise send slug and porofile id will be retrieved at the backend
  }, []);

  const handleCategoryChange = (event, newValue) => {
    console.log(newValue)
    setActiveCategory(newValue);
    const element = document.getElementById(`category-${newValue}`);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchServices = async (params) => {
    try {
      setLoading(true);
      const response = await dataProvider.getList('services', {
        filter: { ...params },
        pagination: { page: 1, perPage: 100 },
        sort: {},
      });
      setProfileId(params.profile ? params.profile : response.data[0].profile);
      setServices(response.data);
      const categories = [...new Set(response.data.map(obj => obj.category))];
      const sortedCategories = categories.sort()
      setCategories(sortedCategories);
      setActiveCategory(sortedCategories[0])

      const urlParams = new URLSearchParams(location.search);
      const sid = urlParams.get('sid');
      sid && setChecked(response.data.filter(s => s.id===sid));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleToggleService = (obj) => () => {
    const { id } = obj;
  
    // if nested services, then just show child services. Otherwise mark as checked.
    if (services.filter(s => s.id===id)[0].subservices.length>0) {
      setOpen(open === id ? '' : id);
    } else {
      const currentIndex = checked.findIndex(obj => obj.id === id);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(obj);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };
  

  const handleToggleSubService = (service, chosen_subservice) => () => {
    const { id } = service;
    const currentIndex = checked.findIndex(obj => obj.id === id);
    const newChecked = [...checked];
    const newService = {...service, chosen_subservice}

    if (currentIndex === -1) {
      newChecked.push(newService);  // adding
    } else {
      if (newChecked[currentIndex].chosen_subservice.id===chosen_subservice.id) {
        newChecked.splice(currentIndex, 1); // removing
      } else {
        newChecked[currentIndex] = {
          ...newChecked[currentIndex],
          chosen_subservice,
        };
      }
        
    }
    setChecked(newChecked);
  };


  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    if (hours === 0) {
      return `${minutes}min`;
    } else {
      if (minutes === 0) {
        return `${hours}h`;
      } else {
        return `${hours}h ${minutes}min`;
      }
    }
  };

  const handleBook = () => {
    setNext('calendar');
  };

  const renderAppBar = () => {
    if (checked.length > 0) {
      return (
        <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              {checked.length} {checked.length===1 ? 'service selected' : 'services selected'}
            </Typography>
            <Button variant="contained" color="secondary" sx={{ mr: 2 }} onClick={handleBook}>
              Book now
            </Button>
          </Toolbar>
        </AppBar>
      );
    } else {
      return null;
    }
  };


  return (
    <>
        {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', p: 1 }}>
          {categories.map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryChange(category)}
              color={activeCategory === category ? 'primary' : 'default'}
              sx={{ m: 1 }}
            />
          ))}
        </Box> */}
      <Box sx={{ bgcolor: 'background.paper' }}>
        <Tabs
            value={activeCategory}
            onChange={handleCategoryChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Categories"
        >
            {categories.map((category, i) => (
                <Tab 
                key={i}
                value={category}
                label={category} 
                />
            ))}
            
        </Tabs>
        </Box>

        {categories.map((category) => (
          <Box key={category} id={`category-${category}`} mt={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              {category}
            </Typography>
            <List sx={{ bgcolor: 'background.paper' }}>
            {services
              .filter((service) => service.category === category)
              .map((service, i) => {
                const serviceLabelId = `s-item-${service.id}`;
                return (
                  <Box key={i} >
                    <Divider variant="inset" component="li" />
                    <ListItemButton role={undefined} onClick={handleToggleService(service)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.findIndex(obj => obj.id === service.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': serviceLabelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                      id={serviceLabelId}
                      primary={
                        <Typography
                          component="div"
                          variant="subtitle1"
                        >
                          {service.name}
                        </Typography>
                      }
                      secondary={
                          <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            {
                              service.duration_sec
                              ? formatDuration(service.duration_sec)
                              : service.min_duration_sec === service.max_duration_sec
                                ? formatDuration(service.min_duration_sec)
                                : `${formatDuration(service.min_duration_sec)} - ${formatDuration(service.max_duration_sec)}`
                            }
                          </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography sx={{pr:'40px'}} variant="body1">{service.min_price ? `from ${getSymbolFromCurrency(service.price_currency)} ${service.min_price}` : `${getSymbolFromCurrency(service.price_currency)} ${service.price}`}</Typography>
                    </ListItemSecondaryAction>
                    {service.subservices.length>0 
                      ? service.id===open
                        ? <ExpandLess /> 
                        : <ExpandMore /> 
                      : ''}
                    </ListItemButton>
                    {service.subservices.length > 0 &&
                      service.id === open && (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <RadioGroup
                              aria-label={`subservices-${service.id}`}
                              value={checked.find((obj) => obj.id === service.id)?.chosen_subservice.id || ''}
                            >
                              {service.subservices
                                .sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
                                .map((ss, i) => {
                                  const ssLabelId = `subservice-${i}`;
                                  return (
                                    <ListItemButton
                                      key={i}
                                      sx={{ pl: 4 }}
                                      role={undefined}
                                      dense
                                      onClick={handleToggleSubService(service, ss)}
                                    >
                                      <ListItemIcon>
                                        <Radio
                                          edge="start"
                                          value={ss.id}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': ssLabelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        id={ssLabelId}
                                        primary={
                                          <Typography component="div" variant="subtitle1">
                                            {ss.name}
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color="textSecondary"
                                          >
                                            {formatDuration(ss.duration_sec)}
                                          </Typography>
                                        }
                                      />
                                      <ListItemSecondaryAction>
                                        <Typography sx={{ pr: '40px' }} variant="body1">
                                          {`${getSymbolFromCurrency(ss.price_currency)} ${ss.price}`}
                                        </Typography>
                                      </ListItemSecondaryAction>
                                    </ListItemButton>
                                  );
                                })}
                            </RadioGroup>
                          </List>
                        </Collapse>
                      )
                    }

                  </Box>
                )
              }
)}
              </List>
          </Box>
        ))}
        {renderAppBar()}
    </>
  );
};

export default ServiceSelect;
