import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import TopBar from '../../components/topBar';
import Footer from '../../components/footer/Footer';
import { useJsApiLoader } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import config from 'config'

const libraries = ['places'];

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

function HomePage() {
  // const loaded = useRef(false);
  // // const { loading, data, error } = useGetOne('services', { pagination: { page: 1, perPage: 10 }, sort: { field: 'name', order: 'asc' }, filter: { category: 'healthcare' } });

  // if (typeof window !== 'undefined' && !loaded.current) {
  //   if (!document.querySelector('#google-maps')) {
  //     loadScript(
  //       `https://maps.googleapis.com/maps/api/js?key=${config.googleAPIKey}&libraries=places`,
  //       document.querySelector('head'),
  //       'google-maps',
  //     );
  //   }

  //   loaded.current = true;
  // }
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.googleAPIKey,
    libraries: libraries,
  })
  
  if (loadError) {
    return <div>Error loading maps</div>; // Display an error message if there is an error while loading
  }

  if (!isLoaded) {
    return <CircularProgress />; // Display a loading indicator while the map is loading
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TopBar />
      <Outlet />
      <Footer />
    </Box>
  );
}

export default HomePage;
