import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { useGetOne, dataProvider } from '../../hooks/dataProvider';
import TimeSlots from './TimeSlots';
import { format, addMinutes, startOfDay, endOfDay } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const Calendar = ({ profileId, services }) => {
  const [activeDate, setActiveDate] = useState('');
  const [venueTimeZone, setVenueTimeZone] = useState('');
  const [loadingHours, setLoadingHours] = useState(true);
  const [allOpenHours, setAllOpenHours] = useState('');
  const [daysofWeek, setDaysOfWeek] = useState([]);
  const [isWorkingDay, setisWorkingDay] = useState(false);
  const [currentOpenHours, setCurrentOpenHours] = useState({});
  const [srvDuration_sec, setSrvDuraton_sec] = useState(0);
  const { loading: loadingRules, data: rulesData } = useGetOne(
    'booking/rules',
    profileId
  );

  useEffect(() => {
    const fetchWorkingHours = async () => {
      try {
        setLoadingHours(true);
        const response = await dataProvider.getOne('booking/working-hours', {
          id: profileId,
        });

        const timeZone = response.data.timezone;
        setVenueTimeZone(timeZone);
        const formattedInTZ = formatInTimeZone(new Date(), timeZone, 'yyyy-MM-dd')
        setActiveDate(formattedInTZ); // Set the initial active date

        const daysOfWeekArray = [...response.data.days_of_week].map(Number);
        setDaysOfWeek(daysOfWeekArray);
        setAllOpenHours(response.data.open_hours);
        setLoadingHours(false);
      } catch (error) {
        setLoadingHours(false);
      }
    };

    fetchWorkingHours();
    const totalDurationSec = services.reduce((sum, item) => sum + item.duration_sec + item.cleanup_time_sec, 0);
    setSrvDuraton_sec(totalDurationSec);
  }, []);

  useEffect(() => {
    const date = new Date(activeDate);
    const dayOfWeekNumber = date.getDay();
    const adjustedDayOfWeek = dayOfWeekNumber === 0 ? 7 : dayOfWeekNumber;
    setisWorkingDay(daysofWeek[adjustedDayOfWeek - 1] === 1);
    setCurrentOpenHours(allOpenHours[adjustedDayOfWeek]);
  }, [activeDate, daysofWeek]);

  const handleDateChange = (dateString) => {
    setActiveDate(dateString);
  };

  const generateDates = () => {
    const currentDate = formatInTimeZone(new Date(), venueTimeZone, 'yyyy-MM-dd HH:mm:ss')
    const dates = [];
    for (let i = 0; i < rulesData?.max_open_period_days; i++) {
      let date = new Date(currentDate);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    console.log("dates ", dates)
    return dates;
  };

  const renderDateTabs = () => {
    const dates = generateDates();
    return dates.map((date) => {
      const dayOfWeek = date.toLocaleString('en-US', {
        weekday: 'short'
      });
      const dayOfMonth = date.getDate();
      const monthAbbreviation = date.toLocaleString('en-US', {
        month: 'short'
      });
      const dayOfWeekNumber = date.getDay();
      const adjustedDayOfWeek = dayOfWeekNumber === 0 ? 7 : dayOfWeekNumber;

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      return (
        <div
          key={dateString}
          className="date-tab"
          onClick={() => handleDateChange(dateString)}
          style={{
            backgroundColor: daysofWeek.includes(adjustedDayOfWeek)
              ? 'inherit'
              : 'lightgrey',
          }}
        >
          <Typography variant="subtitle2">{dayOfWeek}</Typography>
          <Typography variant="subtitle1" color="text.primary">
            {dayOfMonth}
          </Typography>
          <Typography variant="caption">{monthAbbreviation}</Typography>
        </div>
      );
    });
  };

  const CustomButtonGroupAsArrows = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <Box>
        <IconButton onClick={() => previous()} sx={{ position: 'absolute', left: '-50px', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }} disabled={currentSlide === 0 ? true : false} >
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={() => next()} sx={{ position: 'absolute', right: '-50px', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }} disabled={currentSlide === rulesData.max_open_period_days - 7 ? true : false}>
          <ChevronRight />
        </IconButton>
      </Box>
    );
  };

  if (loadingHours || loadingRules) return "loading..."

  return (
    <Box>
      <Box sx={{ position: 'relative', margin: '0 50px' }}>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          customTransition="all 1s"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 7,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 3,
              partialVisibilityGutter: 30
            }
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={7}
          swipeable
          renderArrowsWhenDisabled={false}
          customButtonGroup={<CustomButtonGroupAsArrows />}
        >
          {renderDateTabs()}
        </Carousel>
      </Box>
      <TimeSlots
        activeDate={activeDate}
        isWorkingDay={isWorkingDay}
        openHours={currentOpenHours}
        timeZone={venueTimeZone}
        profileId={profileId}
        srvDuration_sec={srvDuration_sec}
        buffer_time_sec={rulesData.buffer_time_sec}
        slot_duration_sec={rulesData.slot_duration_sec}
        services={services}
      />
    </Box>
  );
};

export default Calendar;
