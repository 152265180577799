import { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
// import PerformerSelect from './PerformerSelect';
import ServiceSelect from './ServiceSelect';
import Calendar from './Calendar';
import Bill from './Bill';
import { useParams } from 'react-router-dom';
import {dataProvider} from '../../hooks/dataProvider';

const BookingPage = () => {
  const [checkedServices, setCheckedServices] = useState([]);
  // const [commonPerformers, setCommonPerformers] = useState([]);
  // const [selectedPerformer, setSelectedPerformer] = useState('');
  const [next, setNext] = useState('services');
  const [profileId, setProfileId] = useState('');
  const [profile, setProfile] = useState({});
  const [errorProfile, setErrorProfile] = useState('')
  const [loadingProfile, setLoadingProfile] = useState('')
  const { slug } = useParams();
  
  useEffect(() => {
    const storedProfileData = localStorage.getItem('profileData');
    if (storedProfileData) {
      const parsedProfile = JSON.parse(storedProfileData);
      parsedProfile.slug === slug ? setProfile(parsedProfile) : fetchProfile(slug);
    }
    else {
      fetchProfile(slug)
    }
  }, []);

  const fetchProfile = async (id) => {
    try {
      const response = await dataProvider.getOne('p/profile', { id } );
      setProfile(response.data);
      localStorage.setItem('profileData', JSON.stringify(response.data));
    } catch (error) {
      setErrorProfile(error);
    } finally {
      setLoadingProfile(false);
    }
  };


//   useEffect(() => {
//     // finding the common performers among all services
//     const performersArray = checkedServices.reduce((commonPerformers, service, index) => {
//       const performers = service.performers;
//       if (index === 0) {
//         // Initialize commonPerformers with the performers from the first service
//         return performers;
//       } else {
//         // Filter out performers that are not present in the current service
//         return commonPerformers.filter((performer) => performers.some((p) => p.id === performer.id));
//       }
//     }, []);
//     setCommonPerformers(performersArray);
// }, [checkedServices]);

  function renderComponent(value) {
    switch (value) {
      case 'services':
        return <ServiceSelect checked={checkedServices} setChecked={setCheckedServices} setNext={setNext} setProfileId={setProfileId} />;
      // case 'staff':
      //   if (commonPerformers.length>0) {
      //     console.log("staff option");
      //     return <PerformerSelect performers={commonPerformers} setNext={setNext} setSelectedPerformer={setSelectedPerformer} />;
      //   } else {
      //     console.log("else calendar option");
      //     // when too many services selected and there are no common performers left that could do all the services.
      //     return <Calendar profileId={profileId} services={checkedServices} />; // this is Salon's profileId
      //   }       
      case 'calendar':
        return <Calendar profileId={profileId} services={checkedServices} />;  // This could be either staff profile Id or Professional Id.
      default:
        return null;
    }
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          {renderComponent(next)}
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Bill profile={profile} services={checkedServices} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BookingPage;
