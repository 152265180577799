import { Box, Typography, Avatar, Rating, Stack, Grid } from '@mui/material';

const reviews = [
  {
    id: 1,
    author: 'John Doe',
    avatar: '/avatars/avatar1.jpg',
    date: 'June 1, 2023',
    comment: 'Great service! Highly recommended.',
    rating: 5
  },
  {
    id: 2,
    author: 'Jane Smith',
    avatar: '/avatars/avatar2.jpg',
    date: 'June 3, 2023',
    comment: 'The best experience I ever had. Amazing work!',
    rating: 4.5
  },
  {
    id: 3,
    author: 'David Johnson',
    avatar: '/avatars/avatar3.jpg',
    date: 'June 5, 2023',
    comment: 'Exceptional service and attention to detail. Will definitely come back.',
    rating: 4
  },
  {
    id: 4,
    author: 'Sarah Williams',
    avatar: '/avatars/avatar4.jpg',
    date: 'June 7, 2023',
    comment: 'Outstanding job! I\'m impressed.',
    rating: 4.8
  },
  {
    id: 5,
    author: 'Michael Brown',
    avatar: '/avatars/avatar5.jpg',
    date: 'June 9, 2023',
    comment: 'Highly professional and skilled team.',
    rating: 4.2
  }
];

const Reviews = () => {
  const averageRating =
    reviews.reduce((total, review) => total + review.rating, 0) / reviews.length;

  return (
    <Box mt={4}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Rating
          name="average-rating"
          value={averageRating}
          readOnly
          size="large"
          sx={{ color: 'text.primary' }} // Set the stars color to primary text color
        />
        <Typography variant="subtitle1" component="span" ml={1}>
          ({reviews.length} reviews)
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {reviews.map((review) => (
          <Grid item xs={12} md={6} key={review.id} sx={{ mb: 3 }}> {/* Apply margin to the Grid item */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={review.avatar} alt={review.author} />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle1" component="span">
                  {review.author}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {review.date}
                </Typography>
              </Box>
            </Box>

            <Rating
              name={`rating-${review.id}`}
              value={review.rating}
              precision={0.5}
              readOnly
              sx={{ color: 'text.primary', mt: 1 }} // Set the stars color to primary text color
            />

            <Typography variant="body1" component="div" mt={1}>
              {review.comment}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reviews;
