import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Grid, Typography, Link as MUILink, Stack } from '@mui/material';
import { useGetOne } from '../../hooks/dataProvider';
import HeaderSection from './HeaderSection';
import Reviews from './Reviews';
import LocationHours from './LocationHours';
import Staff from './Staff';
import Services from './Services';
import Posts from './Posts';


function ProfilePage() {
  const { slug } = useParams();
  const { loading, data, error } = useGetOne('p/profile', slug);
  const postsRef = useRef(null);
  const servicesRef = useRef(null);
  const reviewsRef = useRef(null);
  const aboutRef = useRef(null);

  if (loading) return 'loading';

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <>
      <Container maxWidth="xl">
        {/* <Header title="Blog" sections={sections} /> */}
        <main>
          <HeaderSection profile={data} />
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ mt:4 }}
            >
              <MUILink
                component="button"
                variant="h6"
                onClick={() => handleScroll(postsRef)}
                sx={{ ml: 2, color: 'black', textDecoration: 'none'  }}
              >
                Work
              </MUILink>
              <MUILink
                    component="button"
                    variant="h6"
                    onClick={() => handleScroll(servicesRef)}
                    sx={{ ml: 2, color: 'grey', textDecoration: 'none' }}
                  >
                    {data.is_company ? "Staff" : "Services"}
              </MUILink>
              <MUILink
                component="button"
                variant="h6"
                onClick={() => handleScroll(reviewsRef)}
                sx={{ ml: 2, color: 'grey', textDecoration: 'none' }}
              >
                Reviews
              </MUILink>
              <MUILink
                component="button"
                variant="h6"
                onClick={() => handleScroll(aboutRef)}
                sx={{ ml: 2, color: 'grey', textDecoration: 'none' }}
              >
                About
              </MUILink>
            </Stack>
            <Divider sx={{ my: 4 }} />
          <div ref={postsRef}>
            <Posts />
          </div>
          

            <div ref={servicesRef}>
              <Typography variant="h5" component="h2" sx={{ mt: 4 }}>
              { data.is_company ? "Staff" : "Services"}
              </Typography>
              { data.is_company 
              ? <Staff categories={data.categories} profile={data} /> 
              : <Services categories={data.categories} profile={data} />
              }
              
            </div>


          <div ref={reviewsRef}>
            <Typography variant="h5" component="h2" sx={{ mt: 4 }}>
              Reviews
            </Typography>
            <Reviews />
          </div>
          <div ref={aboutRef}>
            <Typography variant="h5" component="h2" sx={{ mt: 4 }}>
              Highlights
            </Typography>
            <LocationHours profile={data} />
          </div>
        </main>
      </Container>
      {/* <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
       */}
    </>
  );
}

export default ProfilePage;
