import React, { useEffect, useState } from 'react';
import { Chip, Typography, CircularProgress, Grid } from '@mui/material';
import ServiceItem from './ServiceItem';
import { dataProvider } from '../../hooks/dataProvider';

function Services({ categories, profile }) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('All');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await dataProvider.getList('services', {
        filter: { profile: profile.id },
        pagination: { page: 1, perPage: 100 },
        sort: {},
      });

      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFilterChange = (category) => {
    setFilter(category);
  };

  const filteredData = filter === 'All' ? data : data.filter((item) => item.category_id===filter);

  if (loading) return <CircularProgress />;

  return (
    <div>
      <Grid container spacing={1} mb={2} flexWrap="wrap">
        <Grid item>
          <Chip
            label="All"
            clickable
            color={filter === 'All' ? 'primary' : 'default'}
            onClick={() => handleFilterChange('All')}
          />
        </Grid>
        {categories.map((category) => (
          <Grid item key={category.id}>
            <Chip
              label={category.name}
              clickable
              color={filter === category.id ? 'primary' : 'default'}
              onClick={() => handleFilterChange(category.id)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} flexWrap="wrap" columns={{ xs: 4, sm: 8, md: 12 }}>
        {filteredData.map((service, i) => (
          <Grid item xs={4} sm={8} md={6} key={i}>
            <ServiceItem service={service} profile={profile} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Services;
