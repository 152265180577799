import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { dataProvider } from '../../hooks/dataProvider';
import ProfileCard from '../../components/ProfileCard';
import Map from './Map';

function SearchPage() {
  // const { slug } = useParams();
  // const { loading, data, error } = useGetOne('p/profile', slug);

    const location = useLocation();
    
    const [catId, setCatId] = useState('');
    const [loc, setLoc] = useState('');

    const [results, setResults] = useState([]);
    const [searchLocation, setSearchLocation] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const param_catId = params.get('catId');
      const param_loc = params.get('loc');
      setCatId(param_catId);
      setLoc(param_loc);

      const fetchResults = async () => {
        try {
          setLoading(true);
          setError(null);

          const response = await dataProvider.getList('p/search/', {
            filter: { cat_id: param_catId, loc: param_loc},
            pagination: { page: 1, perPage: 30 },
            sort: {},
          });
          
          // Set the fetched results in the state
          setResults(response.data);
          setSearchLocation(response.search_data);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
  
      // Call the fetchResults function
      fetchResults();
    }, [location.search]);

  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
  
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              maxHeight: 'calc(100vh - 112px)', // Adjust the 64px value if needed based on the height of your TopBar component
              overflowY: 'auto',
            }}
          >
            {results?.map((profile) => (
                <ProfileCard key={profile.id} profile={profile} />
              ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ display: { xs: 'none', md: 'block', position: 'sticky', top: 0  } }}>
          <Map searchLocation={searchLocation} profiles={results} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SearchPage;
