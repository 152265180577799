import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { format } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { dataProvider } from '../../hooks/dataProvider';

const TimeSlots = ({ openHours, activeDate, isWorkingDay, timeZone, profileId, srvDuration_sec, buffer_time_sec, slot_duration_sec, services }) => {
  const [bookedTimes, setBookedTimes] = useState([]);
  const [loadingBooked, setLoadingBooked] = useState(true);
  const dateUTC = zonedTimeToUtc(activeDate, timeZone)

  useEffect(() => {
    const fetchBookedTimes = async () => {
      try {
        setLoadingBooked(true);
        const response = await dataProvider.getList(`booking/booked_times/${profileId}/${activeDate}/`,{
          filter: {},
          pagination: { page: 1, perPage: 100 },
          sort: {},
        });
        setBookedTimes(response.data);
        setLoadingBooked(false);
      } catch (error) {
        setLoadingBooked(false);
      }
    };

    fetchBookedTimes();
  }, [activeDate]);

  const handleSlot = (timestamp) => {
    console.log("when ", timestamp);
    console.log("what ", services);
  };



  const renderTimeSlots = () => {
    const timeSlots = [];
    const serviceDuration = srvDuration_sec * 1000; // milliseconds
    const slotDuration = slot_duration_sec * 1000; // milliseconds
    const bufferTime = buffer_time_sec * 1000; // milliseconds

    const openingTimestamp = dateUTC.getTime() + openHours[0] * 1000;
    const closingTimestamp = dateUTC.getTime() + openHours[1] * 1000;
    let previousEndTime = openingTimestamp;
    const currentTimestamp = Date.now(); // Get the current timestamp in milliseconds
    const bufferEndTime = currentTimestamp + bufferTime; // Calculate the buffer end time
  
    for (let i = 0; i < bookedTimes.length; i++) {
      const { start, end } = bookedTimes[i];
  
      const startTimestamp = Date.parse(start);
      const endTimestamp = Date.parse(end);
  
      // Check if there is an interval between the previous end time and the current booking start time
      if (previousEndTime < startTimestamp && startTimestamp < closingTimestamp) {
        let currentSlotTime = previousEndTime;
  
        while (currentSlotTime + serviceDuration <= startTimestamp && currentSlotTime + slotDuration <= startTimestamp) {
          // Skip slots that are already in the past
          if (currentSlotTime >= bufferEndTime) {
            const timeSlot = new Date(currentSlotTime);
            const options = { hour: '2-digit', minute: '2-digit', timeZone: timeZone };
            const timeString = timeSlot.toLocaleTimeString([], options);
            const timestamp = timeSlot.getTime(); // Get the timestamp in milliseconds
  
            timeSlots.push(
              <Button key={timestamp} variant="outlined" size="small" onClick={() => handleSlot(timestamp)}>
                {timeString}
              </Button>
            );
          }
  
          currentSlotTime += slotDuration;
        }
      }
  
      // Assign booking end time to previousEndTime, only if this booking is in the current day.
      previousEndTime = endTimestamp > openingTimestamp && endTimestamp <= closingTimestamp ? endTimestamp : previousEndTime;
    }
  
    // Check if there is an interval between the last booking end time and the salon closing time
    if (previousEndTime < closingTimestamp) {
      let currentSlotTime = previousEndTime;
  
      while (currentSlotTime + serviceDuration <= closingTimestamp) {
        // Skip slots that are already in the past
        if (currentSlotTime >= bufferEndTime) {
          const timeSlot = new Date(currentSlotTime);
          const options = { hour: '2-digit', minute: '2-digit', timeZone: timeZone };
          const timeString = timeSlot.toLocaleTimeString([], options);
          const timestamp = timeSlot.getTime(); // Get the timestamp in milliseconds
  
          timeSlots.push(
            <Button key={timestamp} variant="outlined" size="small" onClick={() => handleSlot(timestamp)}>
              {timeString}
            </Button>
          );
        }
  
        currentSlotTime += slotDuration;
      }
    }
  
    return timeSlots;
  };
    
  if (!isWorkingDay) return "weekend...";
  return <div>{renderTimeSlots()}</div>;
};

export default TimeSlots;
