import { Grid, Button, Typography } from "@mui/material";
import config from 'config';


function LocationHours(props) {
  const { profile } = props;

  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${profile.latitude},${profile.longitude}`;
  const mapSrc = `https://maps.googleapis.com/maps/api/staticmap?center=${profile.latitude},${profile.longitude}&zoom=16&size=600x400&maptype=roadmap&key=${config.googleAPIKey}&markers=color:red%7C${profile.latitude},${profile.longitude}`;

  const businessAddress = profile.addresses.find(address => address.is_business_address);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} lg={8}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="body1" sx={{ textAlign: "left" , margin: "0 auto" }}>
              {profile.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ textAlign: "left"  }}>
              Address
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "left" }}>
              {businessAddress?.address}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "left" }}>
              {businessAddress?.city}
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "left"  }}>
              Working hours
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Mon 9am - 6pm
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Tue  9am - 6pm
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Wed 9am - 6pm
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Thu   9am - 6pm
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Fri    9am - 6pm
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <a href={mapUrl} target="_blank" rel="noopener noreferrer">
          <img src={mapSrc} alt="Map" style={{ width: "100%", height: "auto" }} />
        </a>
      </Grid>
    </Grid>
  );
}

export default LocationHours;