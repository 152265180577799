import React from 'react';
import { GoogleMap, MarkerF, MarkerClusterer } from '@react-google-maps/api';
import { Box } from '@mui/material';



export default function Map({searchLocation, profiles}) {
  const mapContainerStyle = {
    flex: 1,
  };

  // const averageLat = profiles.reduce((sum, marker) => sum + marker.lat, 0) / profiles.length;
  // const averageLng = profiles.reduce((sum, marker) => sum + marker.lng, 0) / profiles.length;

  const center = {
    lat: searchLocation.latitude,
    lng: searchLocation.longitude
    // lat: averageLat,
    // lng: averageLng,
  };

  const options = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    gridSize: 60,
    maxZoom: 15,
  };


  return (
    <Box 
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 112px)', // Adjust the 64px value if needed based on the height of your TopBar component
    }}
    >
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={13}
      center={center}
    >
        <MarkerClusterer options={options}>
            {(clusterer) => 
              profiles.map((marker, i) => {
                return (
                  <MarkerF
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                    key={i}
                    clusterer={clusterer}
                  />
                )
              })
            }
          </MarkerClusterer>
    </GoogleMap>
    </Box>
  );
}


