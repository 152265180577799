import { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Autocomplete, Box, Button, CircularProgress, IconButton, InputBase, Stack, Toolbar, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import CategorySearch from './CategorySearch';
import LocationSearch from './LocationSearch';
import {dataProvider} from '../../hooks/dataProvider';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


function TopBar() {
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const navigate = useNavigate();
  const routerLocation = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const catId = queryParams.get('catId');
    const loc = queryParams.get('loc');

    if (catId) {
      const fetchCategory = async () => {
        try {
          const response = await dataProvider.getOne('categories', {id: catId});
          const cat = response.data;
          setCategory(cat);
        } catch (error) {
          console.error('Error fetching category:', error);
        }
      };
      fetchCategory();
    }
    if (loc) {
      setLocation(loc);
    }
  }, [routerLocation]);


  const handleCategoryChange = (newValue) => {
    setCategory(newValue);

    if (newValue && location !== '') {
      const url = `/search?catId=${encodeURIComponent(newValue.id)}&loc=${encodeURIComponent(typeof location === 'string' ? location : location.description)}`;
      navigate(url);
    }
  };

  const handleLocationChange = (newValue) => {
    setLocation(newValue);

    if (newValue && category !== '') {
      const url = `/search?catId=${encodeURIComponent(category.id)}&loc=${encodeURIComponent(newValue.description)}`;
      navigate(url);
    }
  };

  const logo = require("../../assets/gpfl_logo_black.png")
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: '#fff',height: '100px', boxShadow: 'none' }}>
        <Toolbar sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'  }}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
            </Link>
            <Box sx={{  display: 'flex', alignItems: 'center' }} >
              <CategorySearch onChange={handleCategoryChange} category={category} />
              <LocationSearch onChange={handleLocationChange} location={location} />
            </Box>
            <Box>
              <Button variant="outlined" sx={{ textTransform: 'none', marginRight: 1 }}>
                Log in
              </Button>
              <Button variant="contained" color="primary" sx={{ textTransform: 'none' }}>
                Sign up
              </Button>
            </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default TopBar;
