import { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { AppBar, Autocomplete, Box, Button, CircularProgress, IconButton, InputBase, Toolbar, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import {dataProvider} from '../../hooks/dataProvider';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';

function CategorySearch({onChange, category}) {
  const [value, setValue] = useState(category);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () => debounce(async (request, callback) => {
      try {
        const response = await dataProvider.getList('p/categories/', {
          filter: { search: request.input },
          pagination: { page: 1, perPage: 10000 },
          sort: {},
        });
        callback(response.data)
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    }, 400),
    []
  );

  useEffect(() => {
    let active = true;
    
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    category && setValue(category);
    category && setOptions([category]);
  }, [category]);

  const handleChange = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Autocomplete
      id="category-search"
      sx={{ width: 300, mr: '-1px' }}
      getOptionLabel={(option) => option.name || ''}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No Categories"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name} 
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: null, // Remove the little arrow at the right edge
            startAdornment: (
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <SearchIcon sx={{ color: 'text.secondary' }} />
              </Box>
            ),
            sx: {
              backgroundColor: 'transparent',
              borderRadius: '27px 0 0 27px',
              '& input': {
                padding: '8px 12px',
                fontSize: 14,
                color: '#333',
              },
              '& .MuiInputAdornment-root': {
                display: 'none', // Remove the clear icon
              },
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        const parts = parse(option.name, []);

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Typography
                    key={index}
                    component="span"
                    sx={{ fontWeight: 'regular' }}
                  >
                    {part.text}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default CategorySearch;
