import {StrictMode} from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import "@fontsource/cardo"; // Defaults to weight 400
// import "@fontsource/cardo/400.css"; // Specify weight
// import "@fontsource/cardo/400-italic.css"; // Specify weight and style

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: { main: "#ff0576" }
  },
  typography: {
    // fontFamily: ['Cardo', 'sans-serif'].join(','),
    h5: {
      fontFamily: 'Cardo, sans-serif', // Apply Cardo font to h6 variant
    },
    h3: {
      fontFamily: 'Cardo, sans-serif', // Apply Cardo font to h6 variant
    },
  },
});


root.render(
  <StrictMode>
    <BrowserRouter>
        <ThemeProvider theme={theme}>
        <CssBaseline />
          <App />
        </ThemeProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
