import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import ProfilePage from "./pages/profilePage";
import BookingPage from "./pages/bookingPage";
import SearchPage from "./pages/searchPage";
// import "./styles.css";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route path=":slug" element={<ProfilePage />} />
        <Route path=":slug/booking" element={<BookingPage />} />
        <Route path="search" element={<SearchPage />} />
      </Route>
    </Routes>
  );
}
