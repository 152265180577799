import queryString from 'query-string';
import config from 'config';
import axios from 'axios';
import { useState, useEffect, useMemo, useCallback } from 'react';

const { stringify } = queryString;
const apiUrl = config.apiUrl;
const endPoints = {
  profile: 'p/profile',
  industries: 'p/industries',
  categories: 'p/categories',
  types: 'p/types',
  services: 'p/services',
  staff: 'p/staff'
};

export const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryParams = {
      sort: JSON.stringify([field, order]),
      page_size: JSON.stringify(perPage),
      page: JSON.stringify(page),
    };

    if (params.filter.hasOwnProperty('search')) {
      Object.assign(queryParams, { search: params.filter.search });
    } else {
      Object.assign(queryParams, { filter: JSON.stringify(params.filter) });
    }

    const endPoint = endPoints[resource] ? endPoints[resource] : resource;
    const url = `${apiUrl}/${endPoint}?${stringify(queryParams)}`;

    try {
      const response = await axios.get(url);
      return {
        data: response.data.results,
        search_data: response.data.search_data,
        total: response.data.count,
      };
    } catch (error) {
      throw new Error(`Failed to fetch data from ${url}: ${error.message}`);
    }
  },

  getOne: async (resource, params) => {
    const endPoint = endPoints[resource] ? endPoints[resource] : resource;
    const url = `${apiUrl}/${endPoint}/${params.id}`;

    try {
      const response = await axios.get(url);
      return { data: response.data };
    } catch (error) {
      throw new Error(`Failed to fetch data from ${url}: ${error.message}`);
    }
  }
};

export const useGetList = (resource, params) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
  
    const getList = useMemo(() => dataProvider.getList, []);
  
    useEffect(() => {
      setLoading(true);
      setError(null);
  
      const fetchData = async () => {
        try {
          const response = await getList(resource, params);
          setData(response.data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [getList, resource, params]);
  
    return { loading, data, error };
  };

  export const useGetOne = (resource, id) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
  
    const getOne = useMemo(() => dataProvider.getOne, []);
  
    useEffect(() => {
      setLoading(true);
      setError(null);
  
      const fetchData = async () => {
        try {
          const response = await getOne(resource, { id } );
          setData(response.data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [getOne, resource, id]);
  
    return { loading, data, error };
  };