import React, { useState } from 'react';
import { Avatar, Typography, List, ListItem, ListItemText, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

const CustomLink = React.forwardRef(({ onClick, to, ...rest }, ref) => {
  return (
    <Link to={to} ref={ref} {...rest} style={{ textDecoration: 'none', color: 'inherit' }} onClick={(event) => onClick && onClick(event)}>
      {rest.children}
    </Link>
  );
});

const StaffProfile = ({ profile }) => {
  const currency = getSymbolFromCurrency(profile.currency);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const displayedServices = showMore ? profile.services : profile.services.slice(0, 3);

  return (
    <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CustomLink to={`/${profile.slug}/booking`}>
        <Avatar alt={profile.name} src={`url_of_avatar_${profile.id}`} sx={{ width: 100, height: 100, mx: 'auto', my: 2 }} />
      </CustomLink>

      <CustomLink to={`/${profile.slug}/booking`}>
        <Typography variant="h5" align="center" gutterBottom>
          {profile.name}
        </Typography>
      </CustomLink>

      <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
        {profile.occupation}
      </Typography>

      <List sx={{ flex: 1, overflow: 'auto' }}>
        {displayedServices.map((service, index) => (
          <ListItem key={index}>
            <ListItemText primary={service.name} secondary={`${currency} ${service.price}`} />
          </ListItem>
        ))}
      </List>

      {profile.services.length > 3 && (
        <Button variant="text" onClick={toggleShowMore}>
          {showMore ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Paper>
  );
};

export default StaffProfile;
