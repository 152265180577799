import React from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

const FooterContainer = styled('footer')({
  backgroundColor: '#FAFAFB',
  color: 'inherit',
  padding: '2rem',
  marginTop: '2rem',
});

const Logo = styled('img')({
  width: '80px',
  marginBottom: '1rem',
});

// const Description = styled(Typography)({
//   fontSize: '12px',
//   '@media (min-width: 960px)': {
//     fontSize: '14px',
//   },
//   marginBottom: '1rem',
// });

const SocialMediaContainer = styled('div')({
  display: 'flex',
  gap: '1rem',
  marginBottom: '1rem',
});

const LinkColumnsContainer = styled('div')({
  marginBottom: '1rem',
});

const LinkColumn = styled('div')({});

const LinkColumnTitle = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '0.5rem',
});

const LinkItem = styled(Link)({
  color: 'inherit',
  textDecoration: 'underline',
  '&:hover': {
    color: 'primary.main',
  },
});

const HorizontalLine = styled('hr')({
  border: 0,
  borderBottom: '1px solid #fff',
  marginBottom: '1rem',
});

const FooterText = styled(Typography)({
  fontSize: '14px',
  textAlign: 'left',
});

const logo = require("../../assets/gpfl_logo_pink.png")

const Footer = () => {
  return (
    <FooterContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Typography variant="body2">
            GetProForLess is the leading-edge professional booking platform that revolutionizes the way individuals connect with skilled experts across various industries.
          </Typography>
          <SocialMediaContainer>
            <IconButton
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://www.twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://www.pinterest.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <PinterestIcon />
            </IconButton>
          </SocialMediaContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
          <LinkColumnsContainer>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={2}>
                <LinkColumn>
                  <LinkColumnTitle variant="body2">Column 1</LinkColumnTitle>
                  <LinkItem href="#">Link 1</LinkItem>
                  <LinkItem href="#">Link 2</LinkItem>
                  {/* Add more links */}
                </LinkColumn>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <LinkColumn>
                  <LinkColumnTitle variant="body2">Column 1</LinkColumnTitle>
                  <LinkItem href="#">Link 1</LinkItem>
                  <LinkItem href="#">Link 2</LinkItem>
                  {/* Add more links */}
                </LinkColumn>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <LinkColumn>
                  <LinkColumnTitle variant="body2">Column 1</LinkColumnTitle>
                  <LinkItem href="#">Link 1</LinkItem>
                  <LinkItem href="#">Link 2</LinkItem>
                  {/* Add more links */}
                </LinkColumn>
              </Grid>
            </Grid>
          </LinkColumnsContainer>
        </Grid>
      </Grid>
      <HorizontalLine />
      <FooterText variant="body2">
        &copy; {new Date().getFullYear()} GetProForLess. All rights reserved.
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
