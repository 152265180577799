import { useEffect, useRef } from 'react';
import { Avatar, Grid, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { blueGrey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import { Link } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';

function getFirstLetters(name) {
  const words = name.trim().split(" ");
  if (words.length === 1) {
    return <span style={{ fontSize: "12px" }}>{words[0].charAt(0).toUpperCase()}</span>;
  } else if (words.length >= 2) {
    const firstLetters = (
      <span style={{ fontSize: "12px" }}>
        {words[0].charAt(0).toUpperCase()}
        {words[1].charAt(0).toUpperCase()}
      </span>
    );
    return firstLetters;
  }
  return "";
}

function HeaderSection(props) {
  const { profile } = props;
  const imageRef = useRef(null);
  const absoluteDivRef = useRef(null);
  const isViewportSmallerThanLg = useMediaQuery('(max-width: 1200px)');

  const handleImageLoad = () => {
    const imageHeight = imageRef.current.clientHeight;
    absoluteDivRef.current.style.height = `${imageHeight}px`;
  };

  const handleResize = () => {
    const imageHeight = imageRef.current.clientHeight;
    absoluteDivRef.current.style.height = `${imageHeight}px`;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const userThemeColor = blueGrey[500];
  return (
    <Grid container spacing={3} sx={{ mt: 2}} >
      <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Avatar
            alt={profile.name}
            src={profile.pic1}
            sx={{ width: 84, height: 84, bgcolor: userThemeColor }}
          >
            {getFirstLetters(profile.name)}
          </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700, textAlign: "left"  }}>
              {profile.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ textAlign: "left"  }} component="div">
              {profile.occupation}
            </Typography>
            {
              profile.host_name &&
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
              at <Link 
              to={`/${profile.host_slug}`} 
              style={{ textDecoration: 'none', color: 'inherit' }}
              >{profile.host_name}
              <LaunchIcon sx={{ fontSize: 'small', marginLeft: '0.3rem' }} />
              </Link>
              </Typography>
            }
          </Grid>
          <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <Button 
              variant="outlined" 
              sx={{
                textTransform: 'none',
              }}
              startIcon={<AddIcon />}
            >
              Follow
            </Button>
            <Button 
              variant="contained" 
              sx={{
                textTransform: 'none',
              }}
              startIcon={<BookOnlineIcon />}
            >
              Book now
            </Button>
          </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 }, position: 'relative' }}>
        <Grid item xs={12} lg={10}>
            <div
              ref={absoluteDivRef}
              style={{
                position: 'absolute',
                top: '-16px',
                right: isViewportSmallerThanLg ? '-24px' : 0,
                width: '305px',
                height: '0px',
                backgroundColor: userThemeColor,
                zIndex: -1,
              }}
            ></div>
            <img
              ref={imageRef}
              src={profile.banner}
              alt={profile.name}
              style={{ width: '100%' }}
              onLoad={handleImageLoad}
            />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderSection;