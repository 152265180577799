import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import getSymbolFromCurrency from 'currency-symbol-map'
import { useNavigate } from 'react-router-dom';

function ServiceItem({ service, profile }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleBook = () => {
    localStorage.setItem('profileData', JSON.stringify(profile));
    navigate(`booking?sid=${service.id}`);
  };

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    if (hours === 0) {
      return `${minutes}min`;
    } else {
      if (minutes === 0) {
        return `${hours}h`;
      } else {
        return `${hours}h ${minutes}min`;
      }
    }
  };

  const formattedDuration = formatDuration(service.duration_sec);

  return (
    <Box mt={2}>
      <Card elevation={0} sx={{border: '1px solid rgba(0, 0, 0, 0.12)', position: 'relative'}}>
        <CardContent onClick={handleOpenDialog}>
          <Typography variant="h6">{service.name}</Typography>
          <Typography variant="subtitle2" color="text.secondary">{formattedDuration}</Typography>
          <Typography variant="subtitle2">{getSymbolFromCurrency(service.price_currency)} {service.price}</Typography>
        </CardContent>
        <IconButton
          onClick={handleOpenDialog}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <AddCircleOutline />
        </IconButton>
      </Card>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle variant="h4">{service.name}</DialogTitle>
        <DialogContent>
          <Typography variant="h6" color="text.secondary">{formattedDuration}</Typography>
          <Typography variant="h6">{getSymbolFromCurrency(service.price_currency)} {service.price}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBook}>Add to booking</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ServiceItem;